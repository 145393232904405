@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.main {
    height: 100%;
    font-family: 'Ubuntu';
}

.content {
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 5;
}

.first {
    background-image: url("Top-NoCrop.jpg");
    background-size: cover;
    padding: 5rem 8rem;
    /* min-height: 30rem; */
    height: min-content;
    width: calc(100% - 16rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.firstParagraph {
    /* width: min(40%, 35ch); */
    width: 100%;
    /* height: min-content; */
    padding-right: 4rem;
    line-height: 1.5;
    font-size: 1.6rem;
    color: #1e5750;
    white-space: pre-wrap;
}

.firstParagraph h3 {
    margin-top: 0;
}

.player {
    min-width: 30rem;
    width: 100%;
    max-height: 35rem;
    /* height: 100%; */
}

.min-height {
    height: min-content;
}

.divider {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    text-align: center;
    font-weight: bold;
    color: white;
    background-image: url("Quote-NoCrop.jpg");
    background-size: cover;
}

.divider span {
    margin: 3rem 5rem;
    width: min(100%, calc(15ch + 60rem));
}

.second {
    display: flex;
    justify-content: center;
    line-height: 1.5;
    background-image: url('Casette.jpg');
    background-size: cover;
    font-size: 1.6rem;
    color: #1e5750;
    padding: 3rem 8rem;
}

.secondParagraph {
    /* width: calc(min(40%, 35ch) + min(60%, 60rem)); */
    width: min(100%, calc(35ch + 60rem));
    display: block;
    margin: 1em 0;
    white-space: pre-wrap;
}

.contact {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 6fr 1fr;
    height: 30rem;
    row-gap: 4%;
    padding: 3rem 12rem;
    font-size: 1.6rem;
    background-image: url('Contact.jpg')
}

.contact textarea {
    resize: none;
    font-family: 'Ubuntu';
    font-size: 0.9rem;
    padding-left: 0.5rem;
    padding-top: 0.5rem;
}

.contact input {
    font-family: 'Ubuntu';
    font-size: 0.9rem;
    padding-left: 0.5rem;
}

.submitContact {
    width: fit-content;
}

@media only screen and (max-width: 1450px) {
    .first {
        padding-top: 4rem;
        flex-direction: column;
        height: fit-content;
    }

    .firstParagraph {
        width: 100%;
        padding-bottom: 3rem;
        margin-bottom: 0;
        padding-right: 0;
    }

    .player {
        width: 100%;
        max-height: 45vw;
    }
}

@media only screen and (max-width: 1000px) {
    .first {
        padding: 5vw 5vw;
        width: calc(100% - 10vw);
    }

    .firstParagraph {
        font-size: 2.9vw;
        padding-bottom: 6vw;
    }

    .player {
        min-width: auto;
    }

    .divider {
        font-size: 5vw;
    }

    .divider span {
        margin: 6vw 10vw;
    }

    .second {
        padding: 5vw;
        font-size: 2.9vw;
    }

    .contact {
        padding: 5vw;
        font-size: 2.9vw;
        height: 60vw;
    }

    .contact input {
        font-size: 1.5vw;
    }

    .contact textarea {
        font-size: 1.5vw;
    }
}