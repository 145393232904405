.penis {
    box-sizing: border-box;
    color: white;
    font-size: 1.2rem;
    width: 100%;
    /* height: 100%; */
    max-height: 35rem;
    /* height: 100%; */
    /* min-height: 30rem; */
    /* height: fit-content; */
    /* height: min(100%, fit-content); */
    /* height: 100%; */
    background: #666666;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    box-shadow: 7px 7px 10px #555555 inset;
}

.topSection {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topSection h2 {
    margin: 0;
    margin-bottom: 0.2rem;
}

.sliderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    margin-left: 1.5rem;
}

.volumeWrapper {
    display: flex;
    align-items: center;
}

.volumeWrapper img {
    height: 2rem;
}

.seekWrapper {
    display: flex;
    align-items: center;
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    cursor: pointer;
    border-radius: 3px;
    margin: 0 1rem;
    background: #b1b1b1;
    background-image: linear-gradient(#ffffff, #ffffff);
    background-size: 0% 100%;
    background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 10px;
    width: 10px;
    background: #fff;
    border-radius: 100%;
    cursor: pointer;
}

input[type="range"]:focus {
    outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
}

.tracks {
    display: grid;
    height: min(100%, auto);
    /* height: 100%; */
    width: 100%;
    overflow-y: scroll;
    row-gap: 0.75rem;
    align-content: start;
}

.tracks::-webkit-scrollbar {
    width: 1.5rem;
    padding-left: 1rem;
}

.tracks::-webkit-scrollbar-track {
    background-color: #7a7a7a;
}

.tracks::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border: #7a7a7a .4rem solid;
}

.tracks::-webkit-scrollbar-thumb:hover {
    background-color: #dadada;
}

.track {
    width: calc(100% - 1rem);
    height: 3.5rem;
    display: grid;
    overflow: hidden;
    background-color: #858585;
    font-size: 0.9rem;
    grid-template-columns: 2.25fr 6fr 5fr 2fr;
    /* justify-items: center; */
    align-content: center;
    line-height: 3.5rem;
    text-align: left;
}

.track:hover {
    background-color: #a3a3a3;
}

.track:hover .playButton img {
    display: inline;
}

.track:hover .number {
    display: none;
}

.titleBar {
    width: calc(100% - 2.5rem);
    margin-bottom: 0.5rem;
    display: grid;
    grid-template-columns: 2.25fr 6fr 5fr 2fr;
    align-content: center;
    text-align: left;
    font-size: 0.9rem;
}

#number {
    display: flex;
    align-items: center;
    justify-content: center;
}

#title {
    margin-left: 1rem;
    padding-right: 3rem;
}

.play {
    display: flex;
    align-items: center;
    justify-content: center;
}

.playButton {
    all: unset;
    height: 2.5rem;
    cursor: pointer;
}

.playButton img {
    display: none;
    width: 2.5rem;
}

.songTitle {
    margin-left: 1rem;
    padding-right: 3rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.artist {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 0.5rem;
}

@media only screen and (max-width: 1450px) {
    .penis {
        max-height: 45vw;
    }
}

@media only screen and (max-width: 1000px) {
    .penis {
        font-size: 2vw;
        padding: 4vw;
    }

    .topSection {
        margin-bottom: 2vw;
    }

    .sliderWrapper {
        margin-left: 3vw;
    }

    .volumeWrapper img {
        height: 4vw;
    }

    .tracks {
        row-gap: 1.3vw;
    }

    .track {
        font-size: 1.5vw;
        height: 6vw;
    }

    .titleBar {
        font-size: 1.5vw;
        width: calc(100% - 3vw);
    }

    .playButton {
        height: 6vw;
        display: flex;
        align-items: center;
    }

    .playButton img {
        width: 5vw;
    }

    input[type="range"] {
        margin: 0 2vw;
        height: 0.4vw;
    }

    input[type="range"]::-webkit-slider-thumb {
        height: 1vw;
        width: 1vw;
    }

    .tracks::-webkit-scrollbar {
        width: 3vw;
        padding: 0;
        margin: 0;
    }

    .tracks::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border: #7a7a7a 0.6vw solid;
    }
}