.footerMain {
    background-color: #333333;
    color: #989898;
    font-weight: bold;
    /* line-height: 4rem; */
    height: 4rem;
    padding-left: 12rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerMain div {
    display: flex;
    font-size: 0.8rem;
    color: #727272;
    flex-direction: column;
    justify-content: space-between;
    height: 2.2rem;
    line-height: normal;
    margin-right: 1.5rem;
}

.footerMain div a {
    color: #898989;
    text-decoration: none;
    grid-column: 2;
}

@media only screen and (max-width: 1000px) {
    .footerMain {
        padding-left: 5vw;
        height: 10vw;
        line-height: 10vw;
        font-size: 2.2vw;
    }

    .footerMain div {
        margin-right: 3vw;
        font-size: 1.6vw;
        height: 4.5vw;
    }
}
