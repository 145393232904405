.headerMain {
    position: sticky;
    top: 0;
    /* margin-top: -5rem; */
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    justify-items: center;
    align-items: center;
    width: 100%;
    height: 5rem;
    background-color: #22809a;
    box-shadow: 0 0 20px 3px #000;
}

.logo {
    height: 4rem;
    margin: 0.5rem;
    filter: drop-shadow(0 0 7px #bbbbbb);
    -webkit-filter: drop-shadow(0 0 7px #bbbbbb);
}

.buttons {
    display: flex;
    justify-content: center;
    grid-column: 3;
    margin-right: 3rem;
}

.textButtons {
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 1rem;
}

.textButtons a {
    font-size: .9rem;
    text-decoration: none;
    color: white;
    white-space: nowrap;
    width: fit-content;
}

.hamburger {
    height: 2rem;
    cursor: pointer;
    display: none;
}

@media only screen and (max-width: 1000px) {
    .textButtons {
        display: none;
    }

    .headerMain {
        height: 15vw;
        box-shadow: 0 0 10px 3px #000;
    }

    .logo {
        height: 10vw;
        margin: 2vw;
    }

    .hamburger {
        height: 6vw;
    }

    .buttons {
        margin-right: 4vw;
    }
}